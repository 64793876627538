import React from 'react';
import ReactDOM from 'react-dom';
import { Portal } from 'evolve-portal';
import Routes from './routes';

import 'evolve-portal/lib/styles.css';
import 'antd/dist/antd.css';
import './global.css';

import * as config from './Config';

ReactDOM.render(
  <Portal config={config} env={window.env}>
    <Routes />
  </Portal>, 
  document.getElementById('root')
);
